import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import BlogCard from '../components/BlogCard';

const BlogsPage = () => {
  const [blogData, setBlogData] = useState(null);
  const [eachBlog, setEachBlog] = useState([]);
  useEffect(() => {
    axios.get('https://app.todayfilings.com/api/blog-data').then((response) => {
      setEachBlog(response.data);
    });
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const blogId = searchParams.get('id');
    // const blogHead = searchParams.get('blogHead');

    if (blogId) {
      axios
        .get(`https://app.todayfilings.com/api/blog/${blogId}`)
        .then((response) => {
          setBlogData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching blog:', error);
          navigate('/404'); // Redirect to a 404 page if the blog is not found
        });
    } else {
      navigate('/404'); // Redirect to a 404 page if the blog ID is missing
    }
  }, []);

  if (!blogData) {
    return <p>Loading...</p>;
  }

  const { blog, blogs } = blogData;
  // console.log(blogData);
  return (
    <Layout>
      <section className='blogs'>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-md-9'>
              <header
                className='section-header aos-init aos-animate'
                data-aos='fade-up'
                data-aos-offset='100'
              >
                <h2>{blog.blog_head}</h2>
                <div className='bar'></div>
              </header>
              <p className='desc'>{blog.blog_content}</p>
              <div
                className=' mt-5 mb-5 aos-init aos-animate'
                data-aos='fade-right'
                data-aos-offset='100'
              >
                <img
                  src={`https://app.todayfilings.com/${blog.blog_img}`}
                  alt='Blog Post'
                  className='blog-img'
                />
              </div>

              <div
                className=' mt-3 aos-init aos-animate'
                data-aos='fade-left'
                data-aos-offset='100'
              >
                {blogs.map((blogs_data, index) => (
                  <div className='desc mt-4 div-margin' key={index}>
                    <h5>
                      <strong>{blogs_data.header}</strong>
                    </h5>
                    <div className='row'>
                      <div className='col-md-1'>
                        <i className='bi bi-check-circle-fill'></i>
                      </div>
                      <div className='col-md-11'>{blogs_data.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-md-3 side-bar-blogs'>
              <header
                className='section-header aos-init aos-animate'
                data-aos='fade-up'
                data-aos-offset='100'
              >
                <h2>Related Articles</h2>
                <div className='bar'></div>
              </header>
              {eachBlog.map((blog, index) => (
                <div key={index}>
                  <a
                    href={`/blogs?id=${blog.id}&title=${encodeURIComponent(
                      blog.blog_head
                    ).replaceAll('%20', '-')}`}
                  >
                    <div className='testimonials-slider'>
                      <div className='swiper-slide'>
                        <div className='testimonial-item'>
                          <div className='row each-blog'>
                            <div className='col-md-3'>
                              <img
                                src={`https://app.todayfilings.com/${blog.blog_img}`}
                                alt='Blog Post'
                                className='img-fluid'
                              />
                            </div>
                            <div className='col-md-9'>
                              <h3>{blog.blog_head}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div
            className='desc mt-4 div-margin aos-init aos-animate'
            data-aos='fade-up'
            data-aos-offset='100'
          >
            <h5>
              <strong>TodayFilings</strong>
            </h5>
            <p>
              TodayFilings is your dependable companion for every step of your
              Indian business journey. We provide fair and skilled legal
              compliance support, including business registration, tax
              compliance, accounting and bookkeeping, and intellectual property
              protection. Allow us to assist you in navigating the difficult
              legal and regulatory requirements so you can concentrate on
              building your business. To learn more, please contact us
              immediately.
            </p>
          </div>
        </div>
      </section>

      <BlogCard />
    </Layout>
  );
};

export default BlogsPage;
