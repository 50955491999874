import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Navigation } from 'swiper';
import axios from 'axios';
import { Link } from 'gatsby';

const BlogCard = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    axios.get('https://app.todayfilings.com/api/blog-data').then((response) => {
      setBlogs(response.data);
    });
  }, []);

  return (
    <section
      id='blogs'
      className='testimonials aos-init aos-animate'
      data-aos='fade-up'
      data-aos-offset='100'
    >
      <div className='container'>
        <header className='section-header mt-5'>
          <h2>Latest Articles </h2>
          <div className='bar'></div>
        </header>
        <div className='clients-slider swiper'>
          <Swiper
            modules={[Autoplay, EffectFade, Navigation]}
            Effect
            speed={100}
            slidesPerView={3}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 40,
              },

              1200: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            <div className='swiper-wrapper align-items-center'>
              <div className='testimonials-slider swiper'>
                <div className='swiper-wrapper'>
                  {blogs.length > 0 ? (
                    blogs.map((blog, index) => (
                      <SwiperSlide key={index}>
                        <a
                          href={`/blogs?id=${
                            blog.id
                          }&title=${encodeURIComponent(
                            blog.blog_head
                          ).replaceAll('%20', '-')}`}
                        >
                          <div className='swiper-slide'>
                            <div className='testimonial-item'>
                              <img
                                src={`https://app.todayfilings.com/${blog.blog_img}`}
                                alt='Blog Post'
                                className='img-fluid'
                              />
                              <div className='blog-content'>
                                <h3>{blog.blog_head}</h3>
                                <p>{blog.blog_content.substring(0, 100)}...</p>
                              </div>
                              <div className='blog-btn '>
                                <Link className='side-bar-btn btn btn-primary'>
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                        </a>
                      </SwiperSlide>
                    ))
                  ) : (
                    <p>
                      Loading...
                      <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    </p>
                  )}
                </div>
                <div className='swiper-pagination'></div>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default BlogCard;
